export const MwcMixin = {
  methods: {
    isMwcPath() {
      const pathName = window.location.href;
      return (
        pathName.includes("https://themonacowatchclub.com")||
        pathName.includes("https://testing-client.themonacowatchclub.com/")
      );
    },
  },
};