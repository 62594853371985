<template>
<div>
    <!-- web trustpilot sec -->
    <div class="row mwcTrustpilotSecBg">
        <div class="col-4"></div>
        <div class="col-4 d-flex justify-content-center">
            <h2 class="mwcNextDrawTxt" v-if="!userSession"> Next Draw in {{ recentDrawCountdown }}</h2>
            <span v-else>
                <h2 class="editableMsgTxt" v-if="editableMessage"> {{ editableMessage }} </h2>
                <h2 class="mwcNextDrawTxt" v-else> Next Draw in {{ recentDrawCountdown }}</h2>
            </span>
        </div>
        <div class="col-4 text-end">
            <!-- <a href="https://www.trustpilot.com/review/raffolux.com"><img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcExcellentImg.svg" alt="" class="cursor-pointer"></a> -->
        </div>
    </div>

    <!-- mob trustpilot sec -->
    <div class="mb-mwcNextDrawSec mb-trustpilotMarginHide" v-if="!userSession">
        <!-- <h4 class="mb-mwcnextDrawBg mb-0" >Next draw in {{ recentDrawCountdown }}</h4> -->
        <!-- <div class="mb-mwcExcellentImgBg">
            <a href="https://www.trustpilot.com/review/raffolux.com"><img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcExcellentImg.svg" alt=""></a>
        </div> -->
    </div>
    <div class="mb-mwcNextDrawSec mb-editabletxtMargin" v-else>
        <h2 class=" mb-0 editableMsgTxt" v-if="editableMessage"> {{ editableMessage }} </h2>
        <h2 class="mwcNextDrawTxt" v-else> Next Draw in {{ recentDrawCountdown }}</h2>
    </div>

</div>
</template>

<script>
import '@/assets/css/welcomePage.css';
import {
    mapGetters
} from 'vuex';
import timer from '@/mixins/timer';

export default {
    mixins: [timer],

    data() {
        return {
            allRaffleData: [],
            userSession: "",
            editableMessage: ""
        }
    },

    computed: {
        ...mapGetters(['getAllRafflesData', 'getWelcomBackgroundData'])
    },
    mounted() {
        this.editable_message()
    },
    created() {
        this.userSession = localStorage.getItem("user_session");
        this.all_raffles()
    },

    methods: {
        async all_raffles() {
            try {
                await this.$store.dispatch('fetchAllRaffles');
                this.allRaffleData = this.getAllRafflesData.allRaffles;
                this.startCountdown_Recent_draw(this.allRaffleData);
            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },
        async editable_message() {
            try {
                await this.$store.dispatch('fetchWelcomBackground');
                let editableMessageData = this.getWelcomBackgroundData;
                const editableMessageObj = editableMessageData.find(item => item.name === "HOME_EDITABLE_MESSAGE");

                if (editableMessageObj) {
                    this.editableMessage = editableMessageObj.value;
                }
            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },
    }
};
</script>
