import { apiURL } from "@/allApi/api";

const state = {
    winnersData : [],
    winnersDataWithLogin : [],
    winnersCertificateData:[],
    winnersCertificateDataWithLogin:[]
};

const getters = {

  getWinnersData: (state) => {
    return state.winnersData;
  },

  getWinnersDataWithLogin: (state) => {
    return state.winnersDataWithLogin;
  },
  getWinnersCertificateData: (state) => {
    return state.winnersCertificateData;
  },
  getWinnersCertificateDataWithLogin: (state) => {
    return state.winnersCertificateDataWithLogin;
  },
};

const mutations = {

  set_Winners: (state, data) => {
    state.winnersData = data;
  },
  set_Winners_WithLogin: (state, data) => {
    state.winnersDataWithLogin = data;
  },
  set_Winners_certificate : (state, data) => {
    state.winnersCertificateData = data;
  },
  set_Winners_certificate_withLogin : (state, data) => {
    state.winnersCertificateDataWithLogin = data;
  }
};

const actions = {

  async fetchWinners({ commit }, lastDate) {
    try {
      const response = await fetch(apiURL.Winners, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "lastDate" : lastDate,
        }),
      });
      const data = await response.json();
      commit("set_Winners", data);
    } catch (error) {
      console.error("Error fetching Winners:", error);
    }
  },
  

  async fetchWinnersWithLogin({ commit }, {userSession, lastDate}) {
    try {
      const response = await fetch(apiURL.winnersWithLogin, {
        
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
           Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "lastDate" : lastDate,
        }),

      });
      const data = await response.json();
      commit("set_Winners_WithLogin", data);
    } catch (error) {
      console.error("Error fetching Winners:", error);
    }
  },

  async fetchWinnersCertificate({ commit } , sort_id) {
    try {
      const response = await fetch(apiURL.winnerCertificate, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "sort_id" : sort_id,
        }),
      });
      const data = await response.json();
      commit("set_Winners_certificate", data);
    } catch (error) {
      console.error("Error fetching Winners Certificate:", error);
    }
  },
  async fetchWinnersCertificateWithLogin({ commit } , {userSession,sort_id}) {
    try {
      const response = await fetch(apiURL.winnerCertificateWithLogin, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "sort_id" : sort_id,
        }),
      });
      const data = await response.json();
      commit("set_Winners_certificate_withLogin", data);
    } catch (error) {
      console.error("Error fetching Winners Certificate:", error);
    }
  },
  

};

export default {
  state,
  mutations,
  actions,
  getters,
};
