import { apiURL } from "@/allApi/api";

const state = {
  charityData : [],
  charityDataWithLogin : [],
};

const getters = {

  getcharityData: (state) => {
    return state.charityData;
  },

  getcharityDataWithLogin: (state) => {
    return state.charityDataWithLogin;
  },
};

const mutations = {

  set_charity: (state, data) => {
    state.charityData = data;
  },
  set_charity_WithLogin: (state, data) => {
    state.charityDataWithLogin = data;
  },
  
};

const actions = {

  async fetchCharity({ commit }) {
    try {
      const response = await fetch(apiURL.charity, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      commit("set_charity", data);
    } catch (error) {
      console.error("Error fetching charity:", error);
    }
  },
  

  async fetchCharityWithLogin({ commit }, userSession) {
    try {
      const response = await fetch(apiURL.charityWithLogin, {
        
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
           Authorization: JSON.parse(userSession),
        },
      });
      const data = await response.json();
      commit("set_charity_WithLogin", data);
    } catch (error) {
      console.error("Error fetching charity:", error);
    }
  },

};

export default {
  state,
  mutations,
  actions,
  getters,
};
