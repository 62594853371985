const apiURL = {
    // auth apis
    signUp: process.env.VUE_APP_API_URL + "/auth/signUp",
    signIn: process.env.VUE_APP_API_URL + "/auth/signIn",
    googleSignUp: process.env.VUE_APP_API_URL + "/auth/signup/google",
    googleSignIn: process.env.VUE_APP_API_URL + "/auth/signin/google",
    facebookSignUp: process.env.VUE_APP_API_URL + "/auth/signup/facebook",
    facebookSignIn: process.env.VUE_APP_API_URL + "/auth/signin/facebook",
    validateToken: process.env.VUE_APP_API_URL + "/auth/validate/token",
    forgotPassword: process.env.VUE_APP_API_URL + "/accounts/update/forgotPassword",
    changeOldPassword: process.env.VUE_APP_API_URL + "/accounts/update/changeOldPassword",
    // home page apis
    winnersCarousel: process.env.VUE_APP_API_URL + "/winners/read",
    allRaffles: process.env.VUE_APP_API_URL + "/raffle/read",
    winnersCarouselWithLogin: process.env.VUE_APP_API_URL + "/winners/read/withLogin",
    allRafflesWithLogin: process.env.VUE_APP_API_URL + "/raffle/read/withLogin",
    welcomBackground : process.env.VUE_APP_API_URL + "/home/background",

    // charity page apis
    charity: process.env.VUE_APP_API_URL + "/charity/read",
    charityWithLogin: process.env.VUE_APP_API_URL + "/charity/read/withLogin",
    // raffle page apis
    fetchRafflePageData: process.env.VUE_APP_API_URL + "/raffle/fetch",
    fetchRafflePageDataWithLogin: process.env.VUE_APP_API_URL + "/raffle/fetch/withLogin",
    fetchQuestion: process.env.VUE_APP_API_URL + "/questions/fetch",
    fetchQuestionWithLogin: process.env.VUE_APP_API_URL + "/questions/fetch/withLogin",
    addCartWithLogin: process.env.VUE_APP_API_URL + "/cart/add/withLogin",
    // winners page apis
    Winners: process.env.VUE_APP_API_URL + "/winners/gallery",
    winnersWithLogin: process.env.VUE_APP_API_URL + "/winners/gallery/WithLogin",

    winnerCertificate :process.env.VUE_APP_API_URL + "/winners/fetch",
    winnerCertificateWithLogin :process.env.VUE_APP_API_URL + "/winners/fetch/withLogin",

    //cart page api's
    fetchCartItems: process.env.VUE_APP_API_URL + "/cart/fetch/user/withLogin",
    deleteCartItems: process.env.VUE_APP_API_URL + "/cart/delete/item/withLogin",
    updateCartItems: process.env.VUE_APP_API_URL + "/cart/update",
    cartPaymentLogin: process.env.VUE_APP_API_URL + "/cart/payment",
    paymentStatus: process.env.VUE_APP_API_URL + "/payment/status/login",
    fetchSucessTickets: process.env.VUE_APP_API_URL + "/payment/success/getTickets/login",
    isCreditApplyed: process.env.VUE_APP_API_URL + "/cart/apply/credit",
    // Account Page Apis
    fetchAccountInfo: process.env.VUE_APP_API_URL + "/accounts/fetch/withLogin",
    updateAccountInfo: process.env.VUE_APP_API_URL + "/accounts/update/info/withLogin",
    changePassword: process.env.VUE_APP_API_URL + "/accounts/update/password/withLogin",
    referral: process.env.VUE_APP_API_URL + "/accounts/fetch/referral/withLogin",
    accVerification: process.env.VUE_APP_API_URL + "/accounts/generate/otp",
    validateOtp: process.env.VUE_APP_API_URL + "/accounts/verify/otp",


    //  guestCheckout page apis
    guestPayment: process.env.VUE_APP_API_URL + "/guest/checkout",
    guestPaymentStatus: process.env.VUE_APP_API_URL + "/payment/status",
    guestFetchSucessTickets: process.env.VUE_APP_API_URL + "/payment/success/getTickets",
    guestAddToCart: process.env.VUE_APP_API_URL + "/cart/add/items",

    // news and blogs apis
   
    newsRead: process.env.VUE_APP_API_URL + "/news/read",
    newsReadWithLogin: process.env.VUE_APP_API_URL + "/news/read/withLogin", 
    newsArticleFetch: process.env.VUE_APP_API_URL + "/news/fetch",



};

module.exports = { apiURL };
