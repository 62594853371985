export default {
    methods: {
        valid_inputs(value) {
            if (value == undefined || value.trim().length == 0) {
                return { valid: false, error: "This field is Required" }
            }
            else if (!/^[a-zA-Z0-9]+$/.test(value.trim())) {
                return { valid: false, error: "Value should only contain alphanumeric characters" };
            }
            else if (value.trim().length >= 50) {
                return { valid: false, error: "value should be less than 50 characters" }
            }
            else{
                return { valid: true, error: '' }
            }
        },
        
        validate_email(value) {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (value.trim() == undefined || value.trim().length == 0) {
                return { valid: false, error: "Email is required" }
            }
            else if (!emailPattern.test(value.trim())) {
                return { valid: false, error: "Please enter a valid email address" }
            }
            else{
                return { valid: true, error: '' }
            }
        },

        validate_phone(value) {
            const phonePattern = /^[0-9]+$/
            if (value.trim() == undefined || value.trim().length == 0) {
                return { valid: false, error: "Please enter phone number" }
            }
            else if (!phonePattern.test(value.trim())) {
                return { valid: false, error: "Contact number should contain only numbers" }
            } 
            else if (value.trim().toString().length < 10 || value.trim().toString().length > 11) {
                return { valid: false, error: "Contact number must be 10 or 11 digits" }
            } 
            else {
                return { valid: true, error: '' }
            }
        },


        validate_password(value) {

            if (value == undefined || value.length == 0) {
                return { valid: false, error: "Password is required"}
            }
            else if (value.toString().length > 30) {
                return { valid: false, error: "Password must be less than 30 characters" }
            } 
            else if (value.toString().length < 6) {
                return { valid: false, error: "Password must be atleast 6 characters" }
            } 
            else {
                return { valid: true, error: '' }
            }
        }
    }
}
