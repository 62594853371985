import { apiURL } from "@/allApi/api";

const state = {
  RafflePageData: [],
  RafflePageDataWithLogin: [],
  questionData: [],
  questionDataWithLogin: [],
  addCartDataWithLogin: [],
  guestAddCartData: []

};

const getters = {

  getRafflePageData: (state) => {
    return state.RafflePageData;
  },
  getRafflePageDataWithLogin: (state) => {
    return state.RafflePageDataWithLogin;
  },
  getQuestionData: (state) => {
    return state.questionData;
  },
  getQuestionDataWithLogin: (state) => {
    return state.questionDataWithLogin;
  },
  getAddCartDataWithLogin: (state) => {
    return state.addCartDataWithLogin;
  },
  getGuestAddCartData: (state) => {
    return state.guestAddCartData;
  },
};

const mutations = {

  set_RafflePage_Data: (state, data) => {
    state.RafflePageData = data;
  },

  set_RafflePage_Data_WithLogin: (state, data) => {
    state.RafflePageDataWithLogin = data;
  },
  set_question_data: (state, data) => {
    state.questionData = data;
  },
  set_question_data_with_login: (state, data) => {
    state.questionDataWithLogin = data;
  },
  set_addCart_with_login: (state, data) => {
    state.addCartDataWithLogin = data;
  },
  set_guest_addCart_with_login: (state, data) => {
    state.guestAddCartData = data;
  },


};

const actions = {

  async fetchRafflePageData({ commit }, raffleId) {
    try {
      const response = await fetch(apiURL.fetchRafflePageData, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "raffle_id": raffleId,
        }),

      });
      const data = await response.json();
      commit("set_RafflePage_Data", data);
    } catch (error) {
      console.error("Error fetching all raffles:", error);
    }
  },


  async fetchRafflePageDataWithLogin({ commit }, { userSession, raffleId }) {
    try {
      const response = await fetch(apiURL.fetchRafflePageDataWithLogin, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "raffle_id": raffleId,
        }),

      });
      const data = await response.json();
      commit("set_RafflePage_Data_WithLogin", data);
    } catch (error) {
      console.error("Error fetching raffles page:", error);
    }
  },

  async fetchQuestions({ commit },question_id) {
    try {
      const response = await fetch(apiURL.fetchQuestion, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "questionId": question_id,
        }),
      });
      const data = await response.json();
      commit("set_question_data", data);
    } catch (error) {
      console.error("Error fetching raffles page:", error);
    }
  },

  async fetchQuestionsWithLogin({ commit }, {userSession,question_id}) {
    try {
      const response = await fetch(apiURL.fetchQuestionWithLogin, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "questionId": question_id,
        }),
      });
      const data = await response.json();
      commit("set_question_data_with_login", data);
    } catch (error) {
      console.error("Error fetching raffles page:", error);
    }
  },

  async addCartWithLogin({ commit }, { userSession, answer, item, user_id }) {
    try {
      const response = await fetch(apiURL.addCartWithLogin, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "answer": answer,
          "item": item,
          "user_id": user_id
        }),

      });
      const data = await response.json();
      commit("set_addCart_with_login", data);
    } catch (error) {
      console.error("Error fetching add cart page:", error);
    }
  },

  async guestAddToCart({ commit }, { userSession, items, user_id }) {
    try {
      const response = await fetch(apiURL.guestAddToCart, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "items": items,
          "userId": user_id
        }),

      });
      const data = await response.json();
      commit("set_guest_addCart_with_login", data);
    } catch (error) {

    }
  },

};

export default {
  state,
  mutations,
  actions,
  getters,
};
