export default {
  methods: {
    scrollTop() {
      setTimeout(() => {
        if ("scrollBehavior" in document.documentElement.style) {
          document.documentElement.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          document.documentElement.scrollTo(0, 0);
        }
      }, 100);
    },
  },
};
