

import { apiURL } from "@/allApi/api";

 const state = {
    signUpData: null,
    signInData: null,
    googleSignUpData: null,
    googleSignInData: null,
    facebookSignUpData: null,
    facebookSignInData: null,
    validateTokenData : null,
    forgotPasswordData : null,
    resetPasswordData : null,
  };

const  getters = {
    getSignUpData: (state) => {
      return state.signUpData;
    },
    getSignInData: (state) => {
      return state.signInData;
    },

    getGoogleSignUpData: (state) => {
      return state.googleSignUpData;
    },
    getGoogleSignInData: (state) => {
      return state.googleSignInData;
    },
    getFacebookSignUpData: (state) => {
      return state.facebookSignUpData;
    },
    getFacebookSignInData: (state) => {
      return state.facebookSignInData;
    },
    getValidateTokenData: (state) => {
      return state.validateTokenData;
    },
    getForgotPasswordData: (state) => {
      return state.forgotPasswordData;
    },
    getResetPasswordData: (state) => {
      return state.resetPasswordData;
    },

  };

const  mutations = {
    set_signUp_data(state, data) {
      state.signUpData = data;
    },
    set_signIn_data(state, data) {
      state.signInData = data;
    },

    set_googleSignUp_data(state, data) {
      state.googleSignUpData = data;
    },
    set_googleSignIn_data(state, data) {
      state.googleSignInData = data;
    },
    
    set_facebookSignUp_data(state, data) {
      state.facebookSignUpData = data;
    },
    set_facebookSignIn_data(state, data) {
      state.facebookSignInData = data;
    },
    set_validate_Token_data(state, data) {
      state.validateTokenData = data;
    },

    set_forgotPassword_data(state, data) {
      state.forgotPasswordData = data;
    },
    set_resetPassword_data(state, data) {
      state.resetPasswordData = data;
    },
  };

const  actions = {

  async sign_up({ commit }, finalSignUpData) {
       
    try {
      const response = await fetch(apiURL.signUp, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(finalSignUpData),
      });
      const data = await response.json();
      commit("set_signUp_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

  async sign_in({ commit }, finalSignInData) {
    try {
      const response = await fetch(apiURL.signIn, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(finalSignInData),
      });
      const data = await response.json();
      commit("set_signIn_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

  async google_sign_up({ commit }) {
    try {
      const response = await fetch(apiURL.googleSignUp, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      commit("set_googleSignUp_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

  async google_sign_in({ commit }) {
    try {
      const response = await fetch(apiURL.googleSignIn, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      commit("set_googleSignIn_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

  async facebook_sign_up({ commit }) {
    try {
      const response = await fetch(apiURL.facebookSignUp, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      commit("set_facebookSignUp_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

  async facebook_sign_in({ commit }) {
    try {
      const response = await fetch(apiURL.facebookSignIn, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      commit("set_facebookSignIn_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

  async validate_Token({ commit }, userSession) {
    try {
      const response = await fetch(apiURL.validateToken, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
      });
      const data = await response.json();
      commit("set_validate_Token_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },


  async forgot_password({ commit }, email) {
    try {
      const response = await fetch(apiURL.forgotPassword, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "email" : email,
        }),
      });
      const data = await response.json();
      commit("set_forgotPassword_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

  async reset_password({ commit }, {userSession, password}) {

    try {
      const response = await fetch(apiURL.changeOldPassword, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "newPassword" : password,
        }),
      });
      const data = await response.json();
      commit("set_resetPassword_data", data);
    } catch (error) {
      console.error("Error:", error);
    }
  },

}

export default {
  state,
  mutations,
  actions,
  getters
};

