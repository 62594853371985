import { apiURL } from "@/allApi/api";

const state = {
  cartItemsData: [],
  deleteCartItemData: [],
  updateCartItemData: [],
  cartPaymentLoginData: [],
  paymentStatusData: [],
  sucessTicketsData: [],
  guestPaymentData: [],
  guestPaymentStatusData: [],
  guestSucessTicketsData:[],
  isCreditApplyedData : []
};

const getters = {
  getCartItemsData: (state) => {
    return state.cartItemsData;
  },

  getDeleteCartItemData: (state) => {
    return state.deleteCartItemData;
  },

  getUpdateCartItemData: (state) => {
    return state.updateCartItemData;
  },

  getCartPaymentLoginData: (state) => {
    return state.cartPaymentLoginData;
  },

  getPaymentStatusData: (state) => {
    return state.paymentStatusData;
  },
  getSucessTicketsData: (state) => {
    return state.sucessTicketsData;
  },
  getGuestPaymentData: (state) => {
    return state.guestPaymentData;
  },
  getGuestPaymentStatusData: (state) => {
    return state.guestPaymentStatusData;
  },
  getGuestSucessTicketsData: (state) => {
    return state.guestSucessTicketsData;
  },
  getIsCreditApplyedData: (state) => {
    return state.isCreditApplyedData;
  },

};

const mutations = {
  set_CartItems: (state, data) => {
    state.cartItemsData = data;
  },

  set_deleteCartItem: (state, data) => {
    state.deleteCartItemData = data;
  },

  set_updateCartItem: (state, data) => {
    state.updateCartItemData = data;
  },

  set_cartPaymentLogin: (state, data) => {
    state.cartPaymentLoginData = data;
  },

  set_paymentStatus: (state, data) => {
    state.paymentStatusData = data;
  },
  set_SucessTickets: (state, data) => {
    state.sucessTicketsData = data;
  },
  set_guestPayment: (state, data) => {
    state.guestPaymentData = data;
  },
  set_guest_paymentStatus: (state, data) => {
    state.guestPaymentStatusData = data;
  },
  set_guest_sucessTickets:  (state, data) => {
    state.guestSucessTicketsData = data;
  },
  set_isCreditApplyed:  (state, data) => {
    state.isCreditApplyedData = data;
  },
  
};

const actions = {

  async fetchCartPage({ commit }, { userSession, user_id }) {

    try {
      const response = await fetch(apiURL.fetchCartItems, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "user_id": user_id,
        }),
      });
      const data = await response.json();
      commit("set_CartItems", data);
    } catch (error) {
      console.error("Error fetching CartItems:", error);
    }
  },

  async deleteCartItem({ commit }, { userSession, user_id, raffle_id }) {
    try {
      const response = await fetch(apiURL.deleteCartItems, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          user_id: user_id,
          raffle_id: raffle_id,
        }),
      });
      const data = await response.json();
      commit("set_deleteCartItem", data);
    } catch (error) {
      console.error("Error  deleteCartItem:", error);
    }
  },

  async updateCartItem({ commit }, { userSession, cart_id, items }) {
    try {
      const response = await fetch(apiURL.updateCartItems, {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          items: items,
          cart_id: cart_id,
        }),
      });
      const data = await response.json();
      commit("set_updateCartItem", data);
    } catch (error) {
      console.error("Error updateCartItem:", error);
    }
  },

  async cartWithLoginPayment({ commit }, { userSession, cartId, userId, paymentType }) {
    try {
      const response = await fetch(apiURL.cartPaymentLogin, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "userId": userId,
          "cartId": cartId,
          "paymentType": paymentType
        }),
      });
      const data = await response.json();
      commit("set_cartPaymentLogin", data);
    } catch (error) {
      console.error("Error fetching cartPaymentLogin:", error);
    }
  },

  async paymentStatusLogin({ commit }, { userSession, transactionId }) {

    try {
      const response = await fetch(apiURL.paymentStatus, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "transactionId": transactionId,
        }),
      });
      const data = await response.json();
      commit("set_paymentStatus", data);
    } catch (error) {
      console.error("Error fetching paymentStatus:", error);
    }
  },

  async fetchSucessTicketsLogin({ commit }, { userSession, transactionId }) {
    try {
      const response = await fetch(apiURL.fetchSucessTickets, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          "transactionId": transactionId,
        }),
      });
      const data = await response.json();
      commit("set_SucessTickets", data);
    } catch (error) {
      console.error("Error fetching SucessTickets:", error);
    }
  },

  async guestPaymentCall({ commit }, { email, firstName, lastName, contact_number, items, paymentType, emailOpt }) {
    try {
      const response = await fetch(apiURL.guestPayment, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "email": email,
          "firstName": firstName,
          "lastName": lastName,
          "contact_number": contact_number,
          "items": items,
          "paymentType": paymentType,
          "emailOpt": emailOpt,
        }),
      });
      const data = await response.json();
    
      commit("set_guestPayment", data);
    } catch (error) {

    }
  },

  async guestPaymentStatusLogin({ commit }, { transactionId }) {

    try {
      const response = await fetch(apiURL.guestPaymentStatus, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "transactionId": transactionId,
        }),
      });
      const data = await response.json();
      commit("set_guest_paymentStatus", data);
    } catch (error) {

    }
  },

  async guestFetchSucessTickets({ commit }, { transactionId }) {
    try {
      const response = await fetch(apiURL.guestFetchSucessTickets, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
          
        },
        body: JSON.stringify({
          "transactionId": transactionId,
        }),
      });
      const data = await response.json();
      commit("set_guest_sucessTickets", data);
    } catch (error) {
      
    }
  },


  async getCreditApplyed({ commit }, { userSession, credit_applied, cart_id }) {

    try {
      const response = await fetch(apiURL.isCreditApplyed, {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: JSON.parse(userSession),
        },
        body: JSON.stringify({
          credit_applied : credit_applied,
          cart_id : cart_id,
        }),
      });
      const data = await response.json();
      commit("set_isCreditApplyed", data);
    } catch (error) {
      console.error("Error get CreditApplyed:", error);
    }
  },

};

export default {
  state,
  mutations,
  actions,
  getters,
};
