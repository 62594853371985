import { apiURL } from "@/allApi/api";

const state = {
    accountInfo: [],
    updateAccount: [],
    changePassword: [],
    referralInfo: [],
    otp: {},
    validateOtp:{}
};

const getters = {
    getAccountInfo: (state) => {
        return state.accountInfo;
    },
    getUpdatedAccountInfo: (state) => {
        return state.updateAccount;
    },
    getChangePasswordInfo: (state) => {
        return state.changePassword;
    },
    getReferralInfo: (state) => {
        return state.referralInfo;
    },
    getveriOtp: (state) => {
        return state.otp;
    },
    getvalidateOtp: (state) => {
        return state.validateOtp;
    }
};

const mutations = {
    set_account_info: (state, data) => {
        state.accountInfo = data;
    },
    set_update_accountinfo: (state, data) => {
        state.updateAccount = data;
    },

    set_changePassword: (state, data) => {
        state.changePassword = data;
    },

    set_referral: (state, data) => {
        state.referralInfo = data;
    },

    set_veri_otp: (state, data) => {
        state.otp = data;
    },
    set_validate_otp: (state, data) => {
        state.validateOtp = data;
    },

};

const actions = {

    async fetchAccountInfo({ commit }, { userSession, user_id }) {
        try {
            const response = await fetch(apiURL.fetchAccountInfo, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: JSON.parse(userSession),
                },
                body: JSON.stringify({
                    "user_id": user_id,
                }),

            });
            const data = await response.json();
            commit("set_account_info", data);
        } catch (error) {

        }
    },

    async updateAccountInfo({ commit }, { userSession, item }) {
        try {
            const response = await fetch(apiURL.updateAccountInfo, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: JSON.parse(userSession),
                },
                body: JSON.stringify(item),

            });
            const data = await response.json();
            commit("set_update_accountinfo", data);
        } catch (error) {

        }
    },

    async changePassword({ commit }, { userSession, item }) {
        try {
            const response = await fetch(apiURL.changePassword, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: JSON.parse(userSession),
                },
                body: JSON.stringify(item),

            });
            const data = await response.json();
            commit("set_changePassword", data);
        } catch (error) {

        }
    },

    async fetchReferralInfo({ commit }, { userSession, referral_id }) {

        try {
            const response = await fetch(apiURL.referral, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: JSON.parse(userSession),
                },
                body: JSON.stringify({
                    "referral_id": referral_id
                }),

            });
            const data = await response.json();

            commit("set_referral", data);
        } catch (error) {

        }
    },

    async fetchVerfiOtp({ commit }, { userSession }) {
        try {
            const response = await fetch(apiURL.accVerification, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: JSON.parse(userSession),
                },
            });
            const data = await response.json();
            commit("set_veri_otp", data);
        } catch (error) {

        }
    },

    async validate_otp({ commit }, { userSession, otp }) {
        try {
            const response = await fetch(apiURL.validateOtp, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: JSON.parse(userSession),
                },
                body: JSON.stringify({
                    "otp": otp,
                }),

            });
            const data = await response.json();
            commit("set_validate_otp", data);
        } catch (error) {

        }
    },


};

export default {
    state,
    mutations,
    actions,
    getters,
};
