<template>
<div>
    <div class="mwcHowToPlaySecBg">
        <h2 class="mwcHowToPlayTxt">HOW TO PLAY</h2>
        <div class="row mwcPlaySecOptionsBg">
            <div class="col-2 p-0">
                <p class="mwcPlayLeftSecIconBg"><em class="fa-light fa-ticket mwcPlaySecTktIcon"></em></p>
            </div>
            <div class="col-10 p-0">
                <h1 class="mwcPlaySelectTktsTxt">Select your tickets </h1>
                <p class="mwcPlaySecChooseTktTxt">Choose how many tickets you would like, we offer generous discounts on larger ticket bundles</p>
            </div>
        </div>
        <div class="row mwcPlaySecOptionsBg">
            <div class="col-2 p-0">
                <p class="mwcPlayLeftSecBg"><em class="fa-light fa-message-question mwcMsgQueIcon"></em></p>
            </div>
            <div class="col-10 p-0">
                <h1 class="mwcPlaySelectTktsTxt">Answer the question</h1>
                <p class="mwcPlaySecChooseTktTxt">Correctly answer the question on our online game to proceed to the next step </p>
            </div>
        </div>
        <div class="row mwcPlaySecOptionsBg">
            <div class="col-2 p-0">
                <p class="mwcPlayLeftSecBg"><em class="fa-light fa-bag-shopping mwcPlayBagIcon"></em></p>
            </div>
            <div class="col-10 p-0">
                <h1 class="mwcPlaySelectTktsTxt">Secure your tickets</h1>
                <p class="mwcPlaySecChooseTktTxt">Secure your tickets and receive your confirmation email</p>
            </div>
        </div>
        <div class="row mwcPlaySecOptionsBg">
            <div class="col-2 p-0">
                <p class="mwcPlayLeftSecBg"><em class="fa-light fa-watch mwcWatchIcon"></em></p>
            </div>
            <div class="col-10 p-0">
                <h1 class="mwcPlaySelectTktsTxt">Win your dream watch!</h1>
                <p class="mwcPlaySecChooseTktTxt">Watch the draw and find out whether you won - draw results are posted on site immediately after the draw</p>
            </div>
        </div>
        <div class="row mwcPlaySecOptionsEarnBg align-items-start">
            <div class="col-2 p-0">
                <p class="mwcPlayLeftSecIconBg"><em class="fa-sharp fa-light fa-coin mwcPlayCoinIcon"></em></p>
            </div>
            <div class="col-10 p-0">
                <h1 class="mwcPlaySelectTktsTxt mb-2">Earn credit for Referrals</h1>
                <p class="mwcPlaySecChooseTktTxt">Top up your credit balance for every successful referral you make:</p>
                <div class="d-flex ">
                    <p class="mwcPlayNumber">1</p>
                    <p class="mwcPlaySecEarnTxt">Share your unique referral sign up link</p>
                </div>
                <div class="d-flex ">
                    <p class="mwcPlayNumber mwcPlayNumberWidth ">2</p>
                    <p class="mwcPlaySecEarnTxt">When your friend signs up and makes a purchase we will credit your account with £25.00 </p>
                </div>
                <button type="button" class="btn mwcPlayGetRefLinkBtn mwcGetRefBtnMobHide" @click="get_referal_link()">Get my Referral Link</button>
                <button type="button" class="btn mwcPlayGetRefLinkBtn mwcGetRefBtnWebHide " @click="get_referal_link_mob()">Get my Referral Link</button>
            </div>
        </div>
   
    </div>
</div>
</template>

<script>
export default {

    data() {
        return {
            userSession: ""
        };
    },
    computed: {

    },
    created() {
        this.userSession = localStorage.getItem("user_session")

    },
    methods: {
        get_referal_link() {
            if (this.userSession) {
                this.$router.push('/accountsPage');
                sessionStorage.setItem("openReferAndEarn", true)
            } else {
                this.$router.push('/signUp')
                localStorage.setItem('openReferral', true)
            }
        },

        get_referal_link_mob() {
            if (this.userSession) {
                this.$router.push('/referAndEarn');
            } else {
                this.$router.push('/signUp')
                localStorage.setItem('openReferralMob', true)
            }
        }


    }
}
</script>
