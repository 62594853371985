import { apiURL } from "@/allApi/api";

const state = {
    newsDataRead : [],
    newsArticleData : [],
};

const getters = {

  getNewsReadData: (state) => {
    return state.newsDataRead;
  },

  getnewsArticleData: (state) => {
    return state.newsArticleData;
  },
 
};

const mutations = {

    set_news_read: (state, data) => {
    state.newsDataRead = data;
  },
  set_news_article_data: (state, data) => {
    state.newsArticleData = data;
  },
 
};

const actions = {

  async fetchNewsData ({ commit }, lastDate) {
    try {
      const response = await fetch(apiURL.newsRead, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "lastDate" : lastDate,
        }),
      });
      const data = await response.json();
      commit("set_news_read", data);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  },
  

  async fetchNewsArticle({ commit },  sort_id) {
    try {
      const response = await fetch(apiURL.newsArticleFetch, {
        
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          "slug": sort_id,
        }),

      });
      const data = await response.json();
      commit("set_news_article_data", data);
    } catch (error) {
      console.error("Error fetching News Article:", error);
    }
  },

  

};

export default {
  state,
  mutations,
  actions,
  getters,
};
