<template>
    <div class="raffDtlsBgColor">
<div v-if="featuredRaffleData" class="featuredRaffleWidth">
    <div class="row mwcFeatureRaffleBg m-0 align-items-center">
        <div class="col-6 p-0">
            <h6 class="mwcRaffleDrawtxt">TIME UNTIL DRAW</h6>
            <div class="d-flex flex-column align-items-start">
                <p class="mwcRaffleDrawTime mb-0">{{countdownTimers[featuredRaffleData.raffle_id]}}</p>
                <div class="mwcDaysTxtBg">
                    <h6 class="mwcRaffleTimerTxt">DAYS</h6>
                    <h6 class="mwcRaffleTimerTxt">HOURS</h6>
                    <h6 class="mwcRaffleTimerTxt">MINS</h6>
                    <h6 class="mwcRaffleTimerTxt">SECS</h6>
                </div>
            </div>
            <h1 class="mwcraffleName">{{ featuredRaffleData.title }}</h1>
            <h4 class="mwcRaffleMarketValue">MARKET VALUE: <span>£{{ featuredRaffleData.market_value ? (featuredRaffleData.market_value).toLocaleString() : '0' }}</span></h4>
            <div class="d-flex justify-content-center flex-column align-items-start">
                <p class="mwcRaffleTktsLeftTxt" v-if="!featuredRaffleData.is_unlimited_entries"><em class="fa-light fa-ticket mwcTktIcon"></em>{{ featuredRaffleData.total_entries - featuredRaffleData.tickets_sold}} tickets remaining</p>
                <button type="button" class="btn mwcRaffleEnterBtn" @click="routeToRaffles(featuredRaffleData)">ENTER COMPETITION<em class="fa-regular fa-chevron-right mwcRaffleChevronIcon"></em></button>
            </div>
        </div>
        <div class="col-6">
            <div class="position-relative">
                <img :src="urlLink + featuredRaffleData.promo_image_url" :alt="featuredRaffleData.promo_image_alt" class="featuredRaffImg" @click="routeToRaffles(featuredRaffleData)">
                <p class="mwcRaffleCost mb-0"> £{{ featuredRaffleData.entry_cost_gbp }}</p>
            </div>
        </div>
    </div>
    <div class="mobMwcFeaturedRaffleBg">
        <h6 class="mwcRaffleDrawtxt">NEXT DRAW</h6>
        <h1 class="mwcraffleName"> {{ featuredRaffleData.title }} </h1>
        <h4 class="mwcRaffleMarketValue">MARKET VALUE: <span> £{{ featuredRaffleData.market_value ? (featuredRaffleData.market_value).toLocaleString() : '0' }}</span></h4>
        <div class="position-relative">
            <img :src="urlLink + featuredRaffleData.promo_image_url" :alt="featuredRaffleData.promo_image_alt" class="mobMwcImg" @click="routeToRaffles(featuredRaffleData)">
            <p class="mwcRaffleCost mb-0"> £{{ featuredRaffleData.entry_cost_gbp }} </p>
        </div>
        <h6 class="mwcRaffleTimeDrawtxt">TIME UNTIL DRAW</h6>
        <div>
            <p class="mwcRaffleDrawTime mb-0">{{countdownTimers[featuredRaffleData.raffle_id]}}</p>
            <div class="mwcDaysTxtBg">
                <h6 class="mwcRaffleTimerTxt">DAYS</h6>
                <h6 class="mwcRaffleTimerTxt">HOURS</h6>
                <h6 class="mwcRaffleTimerTxt">MINS</h6>
                <h6 class="mwcRaffleTimerTxt">SECS</h6>
            </div>
        </div>
        <div class="d-flex justify-content-center flex-column align-items-center">
            <p class="mwcRaffleTktsLeftTxt" v-if="!featuredRaffleData.is_unlimited_entries"><em class="fa-light fa-ticket mwcTktIcon"></em>{{ featuredRaffleData.total_entries - featuredRaffleData.tickets_sold }} tickets remaining</p>
            <button type="button" class="btn mwcRaffleEnterBtn" @click="routeToRaffles(featuredRaffleData)">ENTER COMPETITION<em class="fa-regular fa-chevron-right mwcRaffleChevronIcon"></em></button>
        </div>
    </div>
</div>
</div>
</template>

<script>
import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'

import {
    mapGetters
} from 'vuex';

import timer from '@/mixins/timer';
export default {
    mixins: [timer],
    data() {
        return {
            featuredRaffleData: [],
            urlLink: urlLink,
            socialImgUrlLink: socialImgUrlLink
        }
    },

    created() {
        this.all_raffles()
    },

    computed: {
        ...mapGetters(['getAllRafflesData'])
    },


    methods: {
        routeToRaffles(item) {

            this.idArray = item;
            this.$router.push({
                name: 'raffleDetailsWithoutLogin',
                params: {
                    id: this.idArray.raffle_id.split('Raffle#')[1],
                }
            });
        },

        async all_raffles() {
            try {
                await this.$store.dispatch('fetchAllRaffles');
                this.featuredRaffleData = this.getAllRafflesData.featuredRaffle[0];
                this.startCountdownSingle(this.featuredRaffleData);
            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        }
    }
}
</script>
