import { createStore } from "vuex";
import homePageApi from "../store/homePageApi";
import cartPageApi from "../store/cartPageApi";
import signInAndSignUpApi from "../store/signInAndSignUpApi";
import rafflePageApi from "../store/rafflePageApi";
import charityPageApi from "../store/charityPageApi";
import winnersPageApi from "../store/winnersPageApi";
import accounts from "../store/accounts";
import newsAndBlogsApi from '../store/newsAndBlogsApi'


export default createStore({
  modules: {
    homePageApi,
    cartPageApi,
    signInAndSignUpApi,
    rafflePageApi,
    charityPageApi,
    winnersPageApi,
    accounts,
    newsAndBlogsApi
  },
});
