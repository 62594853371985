import moment from "moment-timezone";

export default {
  
data() {
  return {
    timer: {},
    countdownTimers : {},
    countdownTimers7days : {},
    countdownTimers7daysSingle : {},
    recentDrawCountdown : null,
    countdownDays : {},
    countdownHours : {},
    countdownMinutes : {},
    countdownSeconds : {},
    timeZoneChange : ""
  };
},

created() {
  this.isDevTestPathChangeTime()
},

beforeDestroy() {
  for (const timerId in this.countdownTimers) {
    clearInterval(this.countdownTimers[timerId]);
  }
},

methods: {

// startCountdown_Recent_draw(timerData) {
//   const nearestDraw = timerData.reduce((nearest, current) => {
//     const countDownDateUTC = moment(current.raffle_expire.split('.000Z')[0]);
//     const diff = countDownDateUTC.diff(moment(), 'milliseconds');
//     if (diff > 0 && (nearest === null || diff < nearest.diff)) {
//       return { date: countDownDateUTC, diff: diff };
//     }
//     return nearest;
//   }, null);

//   if (nearestDraw) {
//     const countDownDateUK = nearestDraw.date.tz(this.timeZoneChange);
//     const countdownTimer = setInterval(() => {
//       const updatedNow = moment();
//       const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
//       const updatedDays = String(Math.floor(updatedDuration.asDays())).padStart(2, '0');
//       const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
//       const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
//       const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');

//       if (updatedDays > 1) {
//         this.recentDrawCountdown = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
//       } else if (updatedDays < 1) {
//         this.recentDrawCountdown = `${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
//       }else{
//         this.recentDrawCountdown = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
//       }

//       if (updatedDuration <= 0) {
//         clearInterval(countdownTimer);
//       }
//     }, 1000);
//   } else {
//     this.recentDrawCountdown = null;
//   }
// },


startCountdown_Recent_draw(timerData) {
  const nearestDraw = timerData.reduce((nearest, current) => {

    const countDownDateUTC = current.raffle_expire.split('.000Z')[0];
    const countDownDateUTC1 = moment.tz(countDownDateUTC, this.timeZoneChange);

    const diff = countDownDateUTC1.diff(moment(), 'milliseconds');
    if (diff > 0 && (nearest === null || diff < nearest.diff)) {
      return { date: countDownDateUTC1, diff: diff };
    }
    return nearest;
  }, null);

  const countDownDateUK = nearestDraw.date.tz(this.timeZoneChange);
  let intervalId;
  const updateCountdown = () => {
    const updatedNow = moment();
    const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
    const updatedDays = String(Math.floor(updatedDuration.asDays())).padStart(2, '0');
    const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
    const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
    const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');

    if (updatedDays > 1) {
      this.recentDrawCountdown = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
    } else if (updatedDays < 1) {
      this.recentDrawCountdown = `${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
    }else{
      this.recentDrawCountdown = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
    }

  };

  updateCountdown();
  intervalId = setInterval(updateCountdown, 1000);
  
},

// startCountdown(timerData) {
//       timerData.forEach((raf) => {
//         const countDownDateUTC = moment(raf.raffle_expire);
//         const countDownDateUK = countDownDateUTC.tz(this.timeZoneChange);
//         const timerId = raf.raffle_id;
//         this.countdownTimers[timerId] = '';
//         const countdownTimer = setInterval(() => {
//           const updatedNow = moment();
//           const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
//           const newUpdatedays = updatedDuration.asDays();
//           const updatedDays = String(parseInt(newUpdatedays)).padStart(2, '0');
//           const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
//           const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
//           const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');
//           this.countdownTimers[timerId] = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
//           if (updatedDuration <= 0) {
//             clearInterval(countdownTimer);
//           }
//         }, 1000);
//       });
// },

startCountdown(timerDataArray) {
  timerDataArray.forEach(timerData => {
    const dateTime = timerData.raffle_expire.split('.000Z')[0];
    const targetDate = moment.tz(dateTime, this.timeZoneChange);
    const timerId = timerData.raffle_id;

    let intervalId; 

    const updateCountdown = () => {
      const updatedNow = moment();
      const updatedDuration = moment.duration(targetDate.diff(updatedNow));
      const updatedDays = String(Math.floor(updatedDuration.asDays())).padStart(2, '0');
      const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
      const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
      const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');

      this.countdownTimers[timerId] = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;

      if (updatedDuration <= 0) {
        clearInterval(intervalId);
        this.countdownTimers[timerId] = "00 : 00 : 00 : 00";
      }
    };

    updateCountdown();
    intervalId = setInterval(updateCountdown, 1000); // Initialize intervalId here
  });
},


// startCountdown7days(timerData) {
//   timerData.forEach((raf) => {
//     const countDownDateUTC = moment(raf.raffle_expire);
//     const countDownDateUK = countDownDateUTC.tz(this.timeZoneChange);
//     const timerId = raf.raffle_id;
//     this.countdownTimers7days[timerId] = '';
//     const countdownTimer = setInterval(() => {
//       const updatedNow = moment();
//       const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
//       const updatedDayOfWeek = countDownDateUK.format('dddd');
//       const newUpdatedDays = updatedDuration.asDays();
//       const updatedDays = Math.floor(newUpdatedDays); 
//       const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
//       const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
//       const timeZoneAbbreviation = countDownDateUK.format('z');
    
//       if (updatedDays >= 2 && updatedDays < 7) {
//         this.countdownTimers7days[timerId] = `NEXT ${updatedDayOfWeek.toUpperCase()} | ${countDownDateUK.format('hA')} ${timeZoneAbbreviation}`;
//       } else if (updatedDays === 1) {
//         this.countdownTimers7days[timerId] = `TOMORROW | ${countDownDateUK.format('hA')} ${timeZoneAbbreviation}`;
//       } else if (updatedDays <= 0) {
//         this.countdownTimers7days[timerId] = `TODAY | ${countDownDateUK.format('hA')} ${timeZoneAbbreviation}`;
//       }else{
//         this.countdownTimers7days[timerId] = `${countDownDateUK.format('dddd Do MMMM')} ${timeZoneAbbreviation}`;
//       }
      
//       if (updatedDuration <= 0) {
//         clearInterval(countdownTimer);
//       }
//     }, 1000);
//   });
// },


startCountdown7days(timerDataArray) {
  timerDataArray.forEach(timerData => {
    const dateTime = timerData.raffle_expire.split('.000Z')[0];
    const targetDate = moment.tz(dateTime, this.timeZoneChange);
    const timerId = timerData.raffle_id;

    const updateCountdown = () => {
      const now = moment.tz(this.timeZoneChange);
      const duration = moment.duration(targetDate.diff(now));

      if (duration.asSeconds() <= 0) {
        // clearInterval(intervalId);
        this.countdownTimers7days[timerId] = `EXPIRED`;
        return;
      } else {
        const updatedNow = moment();
        const updatedDuration = moment.duration(targetDate.diff(updatedNow));
        const updatedDayOfWeek = targetDate.format('dddd');
        const newUpdatedDays = updatedDuration.asDays();
        const updatedDays = Math.floor(newUpdatedDays);
        const timeZoneAbbreviation = targetDate.format('z');

        if (updatedDays >= 2 && updatedDays < 7) {
          this.countdownTimers7days[timerId] = `NEXT ${updatedDayOfWeek.toUpperCase()} | ${targetDate.format('hA')} ${timeZoneAbbreviation}`;
        } else if (updatedDays === 1) {
          this.countdownTimers7days[timerId] = `TOMORROW | ${targetDate.format('hA')} ${timeZoneAbbreviation}`;
        } else if (updatedDays <= 0) {
          this.countdownTimers7days[timerId] = `TODAY | ${targetDate.format('hA')} ${timeZoneAbbreviation}`;
        } else {
          this.countdownTimers7days[timerId] = `${targetDate.format('dddd Do MMMM')} ${timeZoneAbbreviation}`;
        }
      }
    };
    updateCountdown();
  });
},


// startCountdown7daysSingle(timerData) {
//     const countDownDateUTC = moment(timerData.raffle_expire);
//     const countDownDateUK = countDownDateUTC.tz(this.timeZoneChange);
//     const timerId = timerData.raffle_id;
//     this.countdownTimers7daysSingle[timerId] = '';
//     const countdownTimer = setInterval(() => {
//       const updatedNow = moment();
//       const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
//       const updatedDayOfWeek = countDownDateUK.format('dddd');
//       const newUpdatedDays = updatedDuration.asDays();
//       const updatedDays = Math.floor(newUpdatedDays); 
//       const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
//       const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
//       const timeZoneAbbreviation = countDownDateUK.format('z');
    
//       if (updatedDays >= 2 && updatedDays < 7) {
//         this.countdownTimers7daysSingle[timerId] = `NEXT ${updatedDayOfWeek.toUpperCase()} | ${countDownDateUK.format('hA')} ${timeZoneAbbreviation}`;
//       } else if (updatedDays === 1) {
//         this.countdownTimers7daysSingle[timerId] = `TOMORROW | ${countDownDateUK.format('hA')} ${timeZoneAbbreviation}`;
//       } else if (updatedDays <= 0) {
//         this.countdownTimers7daysSingle[timerId] = `TODAY | ${countDownDateUK.format('hA')} ${timeZoneAbbreviation}`;
//       }else{
//         this.countdownTimers7daysSingle[timerId] = `${countDownDateUK.format('dddd Do MMMM')} ${timeZoneAbbreviation}`;
//       }
      
//       if (updatedDuration <= 0) {
//         clearInterval(countdownTimer);
//       }
//     }, 1000);
// },

startCountdown7daysSingle(timerData) {
  const dateTime = timerData.raffle_expire.split('.000Z')[0];
  const targetDate = moment.tz(dateTime, this.timeZoneChange);
  const timerId = timerData.raffle_id;

  const updateCountdown = () => {
    const now = moment.tz(this.timeZoneChange);
    const duration = moment.duration(targetDate.diff(now));

    if (duration.asSeconds() <= 0) {
      clearInterval(intervalId);
      this.countdownTimers7daysSingle[timerId] = `EXPIRED`;
      return;
    } else {
      const updatedNow = moment();
      const updatedDuration = moment.duration(targetDate.diff(updatedNow));
      const updatedDayOfWeek = targetDate.format('dddd');
      const newUpdatedDays = updatedDuration.asDays();
      const updatedDays = Math.floor(newUpdatedDays);
      const timeZoneAbbreviation = targetDate.format('z');

      if (updatedDays >= 2 && updatedDays < 7) {
        this.countdownTimers7daysSingle[timerId] = `NEXT ${updatedDayOfWeek.toUpperCase()} | ${targetDate.format('hA')} ${timeZoneAbbreviation}`;
      } else if (updatedDays === 1) {
        this.countdownTimers7daysSingle[timerId] = `TOMORROW | ${targetDate.format('hA')} ${timeZoneAbbreviation}`;
      } else if (updatedDays <= 0) {
        this.countdownTimers7daysSingle[timerId] = `TODAY | ${targetDate.format('hA')} ${timeZoneAbbreviation}`;
      } else {
        this.countdownTimers7daysSingle[timerId] = `${targetDate.format('dddd Do MMMM')} ${timeZoneAbbreviation}`;
      }
    }
  };

  updateCountdown();
  const intervalId = setInterval(updateCountdown, 1000);
},


// startCountdownSingle(timerData) {
//     const countDownDateUTC = moment(timerData.raffle_expire);
//     const countDownDateUK = countDownDateUTC.tz(this.timeZoneChange);
//     const timerId = timerData.raffle_id;
//     this.countdownTimers[timerId] = '';
//     const countdownTimer = setInterval(() => {
//       const updatedNow = moment();
//       const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
//       const newUpdatedays = updatedDuration.asDays();
//       const updatedDays = String(parseInt(newUpdatedays)).padStart(2, '0');
//       const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
//       const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
//       const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');
//       this.countdownTimers[timerId] = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
//       if (updatedDuration <= 0) {
//         clearInterval(countdownTimer);
//       }
//     }, 1000);
// },

startCountdownSingle(timerData) {
  const dateTime = timerData.raffle_expire.split('.000Z')[0];
  const targetDate = moment.tz(dateTime, this.timeZoneChange);
  const timerId = timerData.raffle_id;

  const updateCountdown = () => {
      const now = moment.tz(this.timeZoneChange);
      const duration = moment.duration(targetDate.diff(now));

      if (duration.asSeconds() <= 0) {
          clearInterval(intervalId);
          this.countdownTimers[timerId] = "00 : 00 : 00 : 00";
          return;
      } else {
          const updatedDays = String(Math.floor(duration.asDays())).padStart(2, '0');
          const updatedHours = String(duration.hours()).padStart(2, '0');
          const updatedMinutes = String(duration.minutes()).padStart(2, '0');
          const updatedSeconds = String(duration.seconds()).padStart(2, '0');
          this.countdownTimers[timerId] = `${updatedDays} : ${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
      }
  };
  // updateCountdown();
  const intervalId = setInterval(updateCountdown, 1000);
}, 

// startCountdownSingle_rafflePage(timerData) {
//   const countDownDateUTC = moment(timerData.raffle_expire);
//   console.log("timerData", countDownDateUTC)

//   console.log("this.timeZoneChange", this.timeZoneChange)
//   const countDownDateUK = countDownDateUTC.tz(this.timeZoneChange);

//   const timerId = timerData.raffle_id;
//   this.countdownDays[timerId] = '';
//   this.countdownHours[timerId] = '';
//   this.countdownMinutes[timerId] = '';
//   this.countdownSeconds[timerId] = '';
//   const countdownTimer = setInterval(() => {
//       const updatedNow = moment();
//       const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
//       this.countdownDays[timerId] = String(Math.floor(updatedDuration.asDays()));
//       this.countdownHours[timerId] = String(updatedDuration.hours()).padStart(2, '0');
//       this.countdownMinutes[timerId] = String(updatedDuration.minutes()).padStart(2, '0');
//       this.countdownSeconds[timerId] = String(updatedDuration.seconds()).padStart(2, '0');
//       if (updatedDuration <= 0) {
//           clearInterval(countdownTimer);
//       }
//   }, 1000);
// },

startCountdownSingle_rafflePage(timerData) {
  let dateTime = timerData.raffle_expire.split('.000Z')[0];
  let targetDate = moment.tz(dateTime, this.timeZoneChange);
  const timerId = timerData.raffle_id;

  const updateCountdown = () => {
      let now = moment.tz(this.timeZoneChange);
      let duration = moment.duration(targetDate.diff(now));

      if (duration.asSeconds() <= 0) {
          clearInterval(intervalId);
          this.countdownDays[timerId] = "00";
          this.countdownHours[timerId] = "00";
          this.countdownMinutes[timerId] = "00";
          this.countdownSeconds[timerId] = "00";
      } else {
          this.countdownDays[timerId] = String(Math.floor(duration.asDays())).padStart(2, '0');
          this.countdownHours[timerId] = String(duration.hours()).padStart(2, '0');
          this.countdownMinutes[timerId] = String(duration.minutes()).padStart(2, '0');
          this.countdownSeconds[timerId] = String(duration.seconds()).padStart(2, '0');
      }
  };
  // updateCountdown();
  const intervalId = setInterval(updateCountdown, 1000);
},

isDevTestPathChangeTime() {
  if (process.env.VUE_APP_ENV === "dev" || process.env.VUE_APP_ENV === "testing") {
    this.timeZoneChange = 'Asia/Kolkata';
  } else {
    this.timeZoneChange = 'Europe/London';
  }
},

},
};
