<template>
<div>
    <v-snackbar v-model="signUpSuccessSnackbar" :timeout="timeout" :color="color">
        <div class="text-center">
            {{ message }}
        </div>
    </v-snackbar>
    <navBar @focusAllRaffles="focusAllRaffles"></navBar>
    <h4 class="mb-mwcnextDrawBg"><span class="nextDrawMargin">Next draw in</span> {{ recentDrawCountdown }}</h4>
    <trustpilotBar></trustpilotBar>
    <welcomePage @enterFeaturedRaffle="enterFeaturedRaffle"></welcomePage>
    <paymentsMethods></paymentsMethods>
    <div ref="featuredRaffle">
        <featuredRaffles></featuredRaffles>
    </div>
    <div ref="allRaffles">
        <allRaffles></allRaffles>
    </div>
    <winnersCarousel></winnersCarousel>
    <howToPlay></howToPlay>
    <h4 class="mwcWillBeWinnerTxt">Will you be our next winner?</h4>
    <div class="d-flex justify-content-center">
        <button type="button" class="btn mwcMobLatestCompetitionBtn" @click="featuredRaffles()">LATEST COMPETITION<em class="fa-regular fa-chevron-right mwcRaffleChevronIcon"></em></button>
    </div>
    <charityPartner></charityPartner>
    <div ref="asSeenIn">
        <asSeenIn></asSeenIn>
    </div>
    <footerView @focusAllRaffles="focusAllRaffles" @asSeenIn="asSeenIn"></footerView>

    <!-- Start of LiveChat (www.livechat.com) code -->
    <noscript>
        <a href="https://www.livechat.com/chat-with/11791575/" rel="nofollow">Chat with us</a>, powered by
        <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
    </noscript>
    <!-- End of LiveChat code -->

</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import timer from '@/mixins/timer';
import {
    jwtDecode
} from 'jwt-decode';

import '@/assets/css/welcomePage.css';
import navBar from '../components/homeWithoutLogin/navBar.vue'
import trustpilotBar from '../components/homeWithoutLogin/trustpilotBar.vue'
import welcomePage from '../components/homeWithoutLogin/welcomePage.vue'
import paymentsMethods from '../components/homeWithoutLogin/paymentsMethods.vue'
import featuredRaffles from '../components/homeWithoutLogin/featuredRaffles.vue'
import allRaffles from '../components/homeWithoutLogin/allRaffles.vue'
import winnersCarousel from '../components/homeWithoutLogin/winnersCarousel.vue'
import howToPlay from '../components/homeWithoutLogin/howToPlay.vue'
import charityPartner from '../components/homeWithoutLogin/charityPartner.vue'
import asSeenIn from '../components/homeWithoutLogin/asSeenIn.vue'
import footerView from '../components/homeWithoutLogin/footerView.vue'
import vaildationMixin from "../mixins/validation"
import '@/assets/css/guestCheckout.css'
import scrollTop from '@/mixins/scrollTop.js'

export default {
    mixins: [timer, vaildationMixin, scrollTop],
    components: {
        navBar,
        trustpilotBar,
        welcomePage,
        paymentsMethods,
        featuredRaffles,
        allRaffles,
        winnersCarousel,
        howToPlay,
        charityPartner,
        asSeenIn,
        footerView
    },
    data: () => ({
        userSession: "",
        userId: "",
        password: '',
        signUpSuccessSnackbar: false,
        referralIdError: null,
        message: "",
        timeout: 3000,
        color: "success",
        isLoading: false,
        guestUserDetails: {},
        error: {
            password: '',
            apiError: '',
        }
    }),

    computed: {
        ...mapGetters(['getAllRafflesData']),

        isFormFilled() {
            return (
                this.password != "" &&
                this.error.password == ""
            );
        },
    },

    created() {
        this.all_raffles()
        this.userSession = localStorage.getItem("user_session");
        if (this.userSession) {
            const decoded = jwtDecode(this.userSession);
            this.userId = decoded.user_id;
             this.$router.push({
                name: 'homeWithLogin',
            });
        } else {
            localStorage.removeItem("user_session");
            const cookieNameToClear = "user_session";
            document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            this.$router.push({
                name: 'homeWithoutLogin',
            });
        }

        this.scrollTop()
    },

    mounted() {
        const currentPath = this.$route.path;
        if (currentPath === '/') {
            this.initializeLiveChat();
        } else {
            const chatWidgetContainer = document.getElementById('chat-widget-container');
            if (chatWidgetContainer) {
                chatWidgetContainer.style.display = 'none';
            }
        }
    },

    methods: {
        enterFeaturedRaffle() {
            this.$refs.featuredRaffle.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        },
        focusAllRaffles() {
            this.$refs.allRaffles.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        },

        asSeenIn() {
            this.$refs.asSeenIn.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        },
        featuredRaffles() {
            this.$refs.featuredRaffle.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        },
        async all_raffles() {
            try {
                await this.$store.dispatch('fetchAllRaffles');
                this.allRaffleData = this.getAllRafflesData.allRaffles;
                this.startCountdown_Recent_draw(this.allRaffleData);
            } catch (error) {

            }
        },

        initializeLiveChat() {
            window.__lc = window.__lc || {};
            window.__lc.license = 11791575;

            (function (n, t, c) {
                function i(n) {
                    return e._h ? e._h.apply(null, n) : e._q.push(n);
                }
                var e = {
                    _q: [],
                    _h: null,
                    _v: "2.0",
                    on: function () {
                        i(["on", c.call(arguments)]);
                    },
                    once: function () {
                        i(["once", c.call(arguments)]);
                    },
                    off: function () {
                        i(["off", c.call(arguments)]);
                    },
                    get: function () {
                        if (!e._h)
                            throw new Error("[LiveChatWidget] You can't use getters before load.");
                        return i(["get", c.call(arguments)]);
                    },
                    call: function () {
                        i(["call", c.call(arguments)]);
                    },
                    init: function () {
                        var n = t.createElement("script");
                        (n.async = !0),
                        (n.type = "text/javascript"),
                        (n.src = "https://cdn.livechatinc.com/tracking.js"),
                        t.head.appendChild(n);
                    },
                };
                !n.__lc.asyncInit && e.init();
                n.LiveChatWidget = n.LiveChatWidget || e;
            })(window, document, [].slice);
        },
    },

}
</script>
