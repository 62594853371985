<template>
<div>
    <div class="mwcAllRafflesBg">
        <h6 class="mwcAllRaffUpcomingtxt">UPCOMING DRAWS</h6>
        <h6 class="mwcAllRafflesAllCompetitiontxt">ALL COMPETITIONS</h6>
        <div class="row mwcAllRaffles">
            <div class="col-6 allRafflesMargin mb-allRafflesWidth allRafflesPadding" v-for="item in allRaffleData" :key="item">

                <div class="web-mwcRaffleTimer">
                    <p class="mwcAllRaffTimeTxt">TIME UNTIL DRAW</p>
                    <p class="mwcAllRaffleTimer mb-0">{{ countdownTimers[item.raffle_id] }}</p>
                    <div class="mwcAllRaffleDaysBg">
                        <h6 class="mwcAllRaffleDaysTxt">DAYS</h6>
                        <h6 class="mwcAllRaffleDaysTxt">HOURS</h6>
                        <h6 class="mwcAllRaffleDaysTxt">MINS</h6>
                        <h6 class="mwcAllRaffleDaysTxt">SECS</h6>
                    </div>
                </div>
                <div class="position-relative">
                    <img :src="urlLink +item.mini_image_url" alt="" class="mwcAllRafflesImg" @click="routeToRaffles(item)">
                    <h6 class="mwcMobTimer mb-0"><em class="fa-solid fa-timer mwcTimerIcon"></em>{{ countdownTimers[item.raffle_id] }}</h6>
                </div>

                <h3 class="mwcAllRafflesName mb-0">{{ item.title }}</h3>
                <p class="mwcAllRafflesDraw webRaffDrawTime"> {{ countdownTimers7days[item.raffle_id] }} </p>
                <p class="mwcAllRafflesDraw mobRaffDrawTime"> {{ countdownTimers7days[item.raffle_id] }} </p>
                <h6 class="mwcAllRafflePrice">£{{ item.entry_cost_gbp }} per ticket</h6>
                <p class="mwcAllRaffEnterTxt" @click="routeToRaffles(item)">ENTER COMPETITION<em class="fa-regular fa-chevron-right mwcAllRaffleChevronIcon"></em></p>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    urlLink,
    socialImgUrlLink
} from '@/mixins/s3bucket.js'
import {
    mapGetters
} from 'vuex';
import timer from '@/mixins/timer';
export default {
    mixins: [timer],
    data() {
        return {
            allRaffleData: [],
            urlLink: urlLink,
            socialImgUrlLink: socialImgUrlLink,

        }
    },
    computed: {
        ...mapGetters(['getAllRafflesData'])
    },

    created() {
        this.all_raffles()
    },
    methods: {
        async all_raffles() {
            try {
                await this.$store.dispatch('fetchAllRaffles');
                this.allRaffleData = this.getAllRafflesData.allRaffles;
                this.startCountdown(this.allRaffleData);
                this.startCountdown7days(this.allRaffleData);
            } catch (error) {
                console.error("Error fetching all raffles:", error);
            }
        },

        routeToRaffles(item) {
            this.idArray = item;
            this.$router.push({
                name: 'raffleDetailsWithoutLogin',
                params: {
                    id: this.idArray.raffle_id.split('Raffle#')[1],
                }
            });
        },
    }
}
</script>
