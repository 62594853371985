<template>
    <div class="mwcAsSeenInSecBg">
        <p class="mwcAsSeenInTxt">AS SEEN IN</p>
        <p class="mwcAFeaturedTxt">FEATURED IN</p>
        <div class="AsSeenSecImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcEntrepreneurImg.svg" alt="" class="mwcEntreorenuerImg">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcMediumImg.svg" alt="" class="mwcAsSeenSecImgs">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcGQImg.svg" alt="" class="mwcAsSeenSecImgs asSeenGqImg" >
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcRevolutionImg.svg" alt="" class="mwcAsSeenSecImgs asSeenRevolutionImg">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcEsquireImg.svg" alt="" class="mwcAsSeenEsquireImg">
            <img src="https://raffolux-mwc.s3.eu-west-2.amazonaws.com/website/images/mwcForbesImg.svg" alt="" class="asSeenGqImg cursor-pointer" >
        </div>
    </div>
</template>